import React, { useState, useEffect } from 'react';
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import Categories from "../components/Route/Categories/Categories";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Events from "../components/Events/Events";
import Footer from "../components/Layout/Footer";
import EventsBanner from '../components/Events/EventsBanner';
import RecentlyViewed from '../components/Route/Recent/RecentlyViewed';
import { useSelector } from "react-redux";


const HomePage = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const { isAuthenticated, user } = useSelector((state) => state.user);

  useEffect(() => {
    const handleResize = () => {
      setIsBannerVisible(window.innerWidth > 900);
    };

    // Set initial value
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <Header activeHeading={1} />
      <Hero />
      <Categories />
      <BestDeals />
      {isBannerVisible ? (
        <EventsBanner /> // Render EventsBanner for screens smaller than 900 pixels
      ) : (
        <Events /> // Render Events component for larger screens
      )}
      <FeaturedProduct />
      {isAuthenticated ? (
        <RecentlyViewed />
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
};

export default HomePage;
