import React, { useState, useEffect } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToWishlist, removeFromWishlist } from "../../../redux/actions/wishlist";
import Cookies from "js-cookie"; // Import js-cookie
import axios from "axios"; // Import axios
import { server } from "../../../server"; // Server URL
import styles from "../../../styles/styles";
import { toast } from "react-toastify";
const MobileProductCard = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const [click, setClick] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  // Function to save product to cookies with only necessary information
  const saveToRecentlyViewed = (product) => {
    const productDetails = {
      _id: product._id,
      name: product.name,
      image: product.images[0]?.url,
      price: product.discountPrice,
      category: product.category,
      description: product.description,
    };

    const recentlyViewed = Cookies.get("recentlyViewed")
      ? JSON.parse(Cookies.get("recentlyViewed"))
      : [];

    // Ensure we don't add duplicate entries for the same product
    const updatedViewed = [productDetails, ...recentlyViewed.filter(item => item._id !== product._id)];

    // Limit to 12 products in the recently viewed list
    const limitedViewed = updatedViewed.slice(0, 12);

    // Save the updated list to cookies, with a 7-day expiration
    Cookies.set("recentlyViewed", JSON.stringify(limitedViewed), { expires: 7 });
  };

  // Handle adding/removing product from wishlist and liking/unliking
  // Handle Wishlist toggle (Add to or remove from wishlist)
  const handleWishlistToggle = async (e) => {
    e.stopPropagation(); // Prevent triggering the card's onClick
    try {
      const response = await axios.put(
        `${server}/product/like-product`,
        { productId: data._id },
        { withCredentials: true }
      );

      if (response.data.success) {
        if (click) {
          dispatch(removeFromWishlist(data));
          setClick(false);
          toast.info('Removed from wishlist!');
        } else {
          dispatch(addToWishlist(data));
          setClick(true);
          toast.success('Added to wishlist!');
        }
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error) {
      toast.error('Cannot watch this product at the moment!');
      console.error('Error during request:', error);
    }
  };

  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Handle product click (e.g., when user views product details)
  const handleProductClick = () => {
    saveToRecentlyViewed(data); // Save product to cookies
  };

  return (
    <div
      className="w-[150px] h-[210px] bg-white dark:bg-[#2b2b2b] dark:shadow-md dark:border-1 dark:border-[#363535] rounded-lg shadow-md p-2 relative cursor-pointer overflow-hidden"
      onClick={handleProductClick} // Save product when clicked
    >
      <div className="flex justify-end z-50">
        <div className="bg-[#646464] rounded-full shadow-md p-2 absolute top-2 right-2 z-60">
          {click ? (
            <AiFillHeart
              size={22}
              className="cursor-pointer"
              onClick={handleWishlistToggle}
              color="#fdd69e"
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={22}
              className="cursor-pointer"
              onClick={handleWishlistToggle}
              color="#d1d1d1"
              title="Add to wishlist"
            />
          )}
        </div>
      </div>
      <Link to={`${isEvent === true ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
        <img
          src={`${data.images && data.images[0]?.url}`}
          alt={data.name}
          className="w-full h-[140px] object-cover bg-gray-100 dark:bg-[#2b2b2b] rounded-md"
        />
      </Link>

      <Link to={`${isEvent === true ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
        <span className="pb-3 overflow-hidden font-bold text-gray-500 dark:text-gray-200 text-sm">
          {data.name.length > 16 ? data.name.slice(0, 12) + "..." : data.name}
        </span>

        <div className="py-2 flex items-center justify-between absolute bottom-0">
          <div className="flex">
            <span className={`${styles.productDiscountPrice} text-xs text-red-300 dark:text-black dark:bg-white dark:p-1 dark:rounded-full dark:font-bold`}>
              RWF {data.originalPrice === 0 ? formatPrice(data.originalPrice) : formatPrice(data.discountPrice)}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default MobileProductCard;
