import React from "react";
import { AiOutlineGift } from "react-icons/ai";
import { MdOutlineLocalOffer } from "react-icons/md";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";
import logo from '../../../Assests/Logo/logo.png'
const DashboardHeader = () => {
  const { seller } = useSelector((state) => state.seller);
  return (
    <div className="w-full h-[80px] bg-white dark:bg-[#1f1f1f] shadow sticky top-0 left-0 z-20 flex items-center justify-between px-4">
      <div>
        <Link to="/dashboard">
          <img
            src={logo}
            alt=""
          />
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-4">
          <Link to="/dashboard-coupouns" className="800px:block hidden">
            <AiOutlineGift
              color="#7c7c7c"
              size={20}
              className="mx-5 cursor-pointer dark:text-gray-200"
            />
          </Link>
          <Link to="/dashboard-events" className="800px:block hidden">
            <MdOutlineLocalOffer
              color="#7c7c7c"
              size={20}
              className="mx-5 cursor-pointer dark:text-gray-200"
            />
          </Link>
          <Link to="/dashboard-products" className="800px:block hidden">
            <FiShoppingBag
              color="#7c7c7c"
              size={20}
              className="mx-5 cursor-pointer dark:text-gray-200"
            />
          </Link>
          <Link to="/dashboard-orders" className="800px:block hidden">
            <FiPackage color="#7c7c7c" size={20} className="mx-5 cursor-pointer dark:text-gray-200" />
          </Link>
          <Link to="/dashboard-messages" className="800px:block hidden">
            <BiMessageSquareDetail
              color="#7c7c7c"
              size={20}
              className="mx-5 cursor-pointer dark:text-gray-200"
            />
          </Link>
          <Link to={`/shop/${seller._id}`}>
            <img
              src={`${seller.avatar?.url}`}
              alt=""
              className="w-[30px] h-[30px] rounded-full object-cover"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
