export const navItems = [
  {
    id: 1,
    title: "Computers",
    url: "/products?search=Computers",
  },
  {
    id: 2,
    title: "Furniture",
    url: "/products?search=furniture",
  },
  {
    id: 3,
    title: "Gaming",
    url: "/products?search=gaming",
  },
  {
    id: 4,
    title: "Automobiles",
    url: "/products?search=automobiles",
  },
  {
    id: 5,
    title: "Beauty",
    url: "/products?search=beauty",
  },
  {
    id: 6,
    title: "Fashion",
    url: "/products?search=clothing",
  },
  {
    id: 7,
    title: "Events",
    url: "/events",
  },
  {
    id: 8,
    title: "Flashsale",
    url: "/flash-sales",
  },
  {
    id: 9,
    title: "Auctions",
    url: "/bids",
  },
];

// branding data
export const brandingData = [
  {
    id: 1,
    title: "Free Shipping",
    Description: "From all orders above RWF 500,000",
    icon: (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1H5.63636V24.1818H35"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M8.72763 35.0002C10.4347 35.0002 11.8185 33.6163 11.8185 31.9093C11.8185 30.2022 10.4347 28.8184 8.72763 28.8184C7.02057 28.8184 5.63672 30.2022 5.63672 31.9093C5.63672 33.6163 7.02057 35.0002 8.72763 35.0002Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M31.9073 35.0002C33.6144 35.0002 34.9982 33.6163 34.9982 31.9093C34.9982 30.2022 33.6144 28.8184 31.9073 28.8184C30.2003 28.8184 28.8164 30.2022 28.8164 31.9093C28.8164 33.6163 30.2003 35.0002 31.9073 35.0002Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M34.9982 1H11.8164V18H34.9982V1Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M11.8164 7.18164H34.9982"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 2,
    title: "Refunds Allowed",
    Description: "Get desired products",
    icon: (
      <svg
        width="32"
        height="34"
        viewBox="0 0 32 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31 17.4502C31 25.7002 24.25 32.4502 16 32.4502C7.75 32.4502 1 25.7002 1 17.4502C1 9.2002 7.75 2.4502 16 2.4502C21.85 2.4502 26.95 5.7502 29.35 10.7002"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M30.7 2L29.5 10.85L20.5 9.65"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 4,
    title: "Affordable Prices",
    Description: "Buy products on actual prices",
    icon: (
      <svg
        width="32"
        height="35"
        viewBox="0 0 32 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 13H5.5C2.95 13 1 11.05 1 8.5V1H7"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M25 13H26.5C29.05 13 31 11.05 31 8.5V1H25"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M16 28V22"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
        ></path>
        <path
          d="M16 22C11.05 22 7 17.95 7 13V1H25V13C25 17.95 20.95 22 16 22Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M25 34H7C7 30.7 9.7 28 13 28H19C22.3 28 25 30.7 25 34Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
  {
    id: 5,
    title: "Secure Payments",
    Description: "100% protected payments",
    icon: (
      <svg
        width="32"
        height="38"
        viewBox="0 0 32 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.6654 18.667H9.33203V27.0003H22.6654V18.667Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M12.668 18.6663V13.6663C12.668 11.833 14.168 10.333 16.0013 10.333C17.8346 10.333 19.3346 11.833 19.3346 13.6663V18.6663"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
        <path
          d="M31 22C31 30.3333 24.3333 37 16 37C7.66667 37 1 30.3333 1 22V5.33333L16 2L31 5.33333V22Z"
          stroke="#FFBB38"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="square"
        ></path>
      </svg>
    ),
  },
];

export const categoriesData = [
  {
    id: 1,
    title: "Computers & Electronics",
    subTitle: "",
    image_Url:
      "https://purepng.com/public/uploads/large/purepng.com-computer-desktopcomputerdesktopdesktop-pcpersonal-computer-1701528348189zsxlc.png",
    subcategories: [
      {
        id: 11,
        title: "Laptops",
        image_Url:
          "https://pngimg.com/uploads/laptop/laptop_PNG5905.png",
      },
      {
        id: 12,
        title: "Desktops",
        image_Url:
          "https://purepng.com/public/uploads/large/purepng.com-computer-desktopcomputerdesktopdesktop-pcpersonal-computer-1701528348189zsxlc.png",
      },
      {
        id: 13,
        title: "Monitors",
        image_Url:
          "https://static.vecteezy.com/system/resources/previews/012/177/419/non_2x/3d-monitor-curved-free-png.png",
      },
      {
        id: 14,
        title: "Tablets",
        image_Url:
          "https://purepng.com/public/uploads/thumbnail/purepng.com-experia-tablettablettablet-computertouchscreen-displayrechargeable-batterelectronicsandroidexperia-tablet-1701528393475kst15.png",
      },
      {
        id: 15,
        title: "Printers & Scanners",
        image_Url:
          "https://purepng.com/public/uploads/thumbnail/purepng.com-printerprinterhuman-readablegraphicstext-on-papercopyistelectronics-1701528367640cessl.png",
      },
      {
        id: 16,
        title: "Networking",
        image_Url:
          "https://purepng.com/public/uploads/thumbnail/purepng.com-routerelectronicsroutermodem-941524672790rjopc.png",
      },
      {
        id: 17,
        title: "Software",
        image_Url:
          "https://purepng.com/public/uploads/thumbnail/purepng.com-ea-games-logologosea-gamesgame-logos-8215239950024jjei.png",
      },
      {
        id: 18,
        title: "Computer Accessories",
        image_Url:
          "https://purepng.com/public/uploads/thumbnail/purepng.com-keyboard-and-mouseelectronicskeyboardmouse-941524672138qqvns.png",
      },
    ],
  },
  {
    id: 2,
    title: "Cosmetics & Personal Care",
    subTitle: "",
    image_Url:
      "https://static.vecteezy.com/system/resources/thumbnails/048/556/555/small/luxury-perfume-bottle-isolated-on-transparent-background-png.png",
    subcategories: [
      {
        id: 21,
        title: "Skincare",
        image_Url:
          "https://africa.cerave.com/en/-/media/Project/Loreal/BrandSites/CeraVe/Master/AfricaHub/English/WhyCeraVe/CeraVe-Is-Born1.png?la=en&hash=363860F2AA747787D3B68E0459E3114084B16886",
      },
      {
        id: 22,
        title: "Makeup",
        image_Url:
          "https://purepng.com/public/uploads/thumbnail/purepng.com-makeup-brushfashioncosmetic-brushclothing-631522936081mzqhg.png",
      },
      {
        id: 23,
        title: "Hair Care",
        image_Url: "https://purepng.com/public/uploads/thumbnail/purepng.com-combfashion-objects-hair-comb-beauty-accessory-hair-brush-brush-hairdresser-styling-salonclothingcomb-631522935972ggw22.png",
      },
      {
        id: 24,
        title: "Fragrances",
        image_Url: "https://static.vecteezy.com/system/resources/thumbnails/048/556/555/small/luxury-perfume-bottle-isolated-on-transparent-background-png.png",
      },
      {
        id: 25,
        title: "Beauty Tools",
        image_Url: "https://img.freepik.com/free-photo/top-view-frame-with-white-tubes-copy-space_23-2148391902.jpg?semt=ais_hybrid",
      },
    ],
  },
  {
    id: 3,
    title: "Home & Furniture",
    subTitle: "",
    image_Url:
      "https://purepng.com/public/uploads/thumbnail/purepng.com-tabletabledeskboardcook-tablefurniture-1701528000524o6krd.png",
    subcategories: [
      {
        id: 31,
        title: "Living Room",
        image_Url: "https://example.com/living-room.jpg",
      },
      {
        id: 32,
        title: "Bedroom",
        image_Url: "https://example.com/bedroom.jpg",
      },
      {
        id: 33,
        title: "Office Furniture",
        image_Url: "https://example.com/office-furniture.jpg",
      },
      {
        id: 34,
        title: "Dining & Kitchen",
        image_Url: "https://example.com/dining-kitchen.jpg",
      },
      {
        id: 35,
        title: "Storage & Organization",
        image_Url: "https://example.com/storage.jpg",
      },
      {
        id: 36,
        title: "Mattresses & Bedding",
        image_Url: "https://example.com/mattresses.jpg",
      },
    ],
  },
  {
    id: 4,
    title: "Clothing & Apparel",
    subTitle: "",
    image_Url:
      "https://purepng.com/public/uploads/thumbnail/purepng.com-fur-clothing-blackfurry-animal-hidesclothingwarmcoatfurblack-1421526314687jmryw.png",
    subcategories: [
      {
        id: 41,
        title: "T-Shirts",
        image_Url: "https://example.com/tshirt.jpg",
      },
      { id: 42, title: "Jeans", image_Url: "https://example.com/jeans.jpg" },
      {
        id: 43,
        title: "Dresses",
        image_Url: "https://example.com/dresses.jpg",
      },
      {
        id: 44,
        title: "Jackets & Outerwear",
        image_Url: "https://example.com/jackets.jpg",
      },
      {
        id: 45,
        title: "Suits & Blazers",
        image_Url: "https://example.com/suits.jpg",
      },
      {
        id: 46,
        title: "Activewear",
        image_Url: "https://example.com/activewear.jpg",
      },
      {
        id: 47,
        title: "Lingerie & Sleepwear",
        image_Url: "https://example.com/lingerie.jpg",
      },
    ],
  },
  {
    id: 5,
    title: "Shoes & Footwear",
    subTitle: "",
    image_Url:
      "https://purepng.com/public/uploads/thumbnail/purepng.com-running-shoesrunning-shoesrunningshoessportingphysical-activitiesstyle-17015281858514ky27.png",
    subcategories: [
      {
        id: 51,
        title: "Sneakers",
        image_Url: "https://example.com/sneakers.jpg",
      },
      { id: 52, title: "Boots", image_Url: "https://example.com/boots.jpg" },
      {
        id: 53,
        title: "Formal Shoes",
        image_Url: "https://example.com/formal-shoes.jpg",
      },
      {
        id: 54,
        title: "Sandals & Flip Flops",
        image_Url: "https://example.com/sandals.jpg",
      },
      {
        id: 55,
        title: "Running Shoes",
        image_Url: "https://example.com/running-shoes.jpg",
      },
    ],
  },
  {
    id: 6,
    title: "Jewelry & Watches",
    subTitle: "",
    image_Url:
      "https://purepng.com/public/uploads/thumbnail/purepng.com-mens-wrist-band-watchclockbelltimealarmwristbandmens-1421526465709alvdw.png",
    subcategories: [
      {
        id: 61,
        title: "Men's Watches",
        image_Url: "https://example.com/mens-watches.jpg",
      },
      {
        id: 62,
        title: "Women's Watches",
        image_Url: "https://example.com/womens-watches.jpg",
      },
      {
        id: 63,
        title: "Necklaces & Chains",
        image_Url: "https://example.com/necklaces.jpg",
      },
      { id: 64, title: "Rings", image_Url: "https://example.com/rings.jpg" },
      {
        id: 65,
        title: "Bracelets & Bangles",
        image_Url: "https://example.com/bracelets.jpg",
      },
      {
        id: 66,
        title: "Earrings",
        image_Url: "https://example.com/earrings.jpg",
      },
    ],
  },
  {
    id: 7,
    title: "Toys & Games",
    subTitle: "",
    image_Url:
      "https://purepng.com/public/uploads/thumbnail/blocks-6rn.png",
    subcategories: [
      {
        id: 71,
        title: "Action Figures",
        image_Url: "https://example.com/action-figures.jpg",
      },
      {
        id: 72,
        title: "Puzzles",
        image_Url: "https://example.com/puzzles.jpg",
      },
      {
        id: 73,
        title: "Board Games",
        image_Url: "https://example.com/board-games.jpg",
      },
      {
        id: 74,
        title: "Educational Toys",
        image_Url: "https://example.com/educational-toys.jpg",
      },
      {
        id: 75,
        title: "Outdoor Toys",
        image_Url: "https://example.com/outdoor-toys.jpg",
      },
    ],
  },
  {
    id: 8,
    title: "Sports & Outdoors",
    subTitle: "",
    image_Url:
      "https://purepng.com/public/uploads/thumbnail/purepng.com-sports-glovesglovesgenuinewhole-handgarmentssportsblack-white-1421526323139clny4.png",
    subcategories: [
      {
        id: 81,
        title: "Cycling",
        image_Url: "https://example.com/cycling.jpg",
      },
      {
        id: 82,
        title: "Camping & Hiking",
        image_Url: "https://example.com/camping.jpg",
      },
      {
        id: 83,
        title: "Fishing",
        image_Url: "https://example.com/fishing.jpg",
      },
      {
        id: 84,
        title: "Swimming",
        image_Url: "https://example.com/swimming.jpg",
      },
      {
        id: 85,
        title: "Running & Jogging",
        image_Url: "https://example.com/running.jpg",
      },
    ],
  },
  {
    id: 9,
    title: "Books & Magazines",
    subTitle: "",
    image_Url:
      "https://purepng.com/public/uploads/thumbnail/bundle-of-books-emd.png",
    subcategories: [
      {
        id: 91,
        title: "Fiction",
        image_Url: "https://example.com/fiction.jpg",
      },
      {
        id: 92,
        title: "Non-Fiction",
        image_Url: "https://example.com/non-fiction.jpg",
      },
      {
        id: 93,
        title: "Children's Books",
        image_Url: "https://example.com/childrens-books.jpg",
      },
      {
        id: 94,
        title: "Textbooks",
        image_Url: "https://example.com/textbooks.jpg",
      },
      {
        id: 95,
        title: "Magazines",
        image_Url: "https://example.com/magazines.jpg",
      },
    ],
  },
  {
    id: 10,
    title: "Food & Beverages",
    subTitle: "",
    image_Url:
      "https://purepng.com/public/uploads/thumbnail/purepng.com-coca-cola-bottlecokecoca-colabeveragedrinksoft-drinkcoke-bottle-1411527238188feivj.png",
    subcategories: [
      { id: 101, title: "Snacks", image_Url: "https://example.com/snacks.jpg" },
      {
        id: 102,
        title: "Beverages",
        image_Url: "https://example.com/beverages.jpg",
      },
      {
        id: 103,
        title: "Health Foods",
        image_Url: "https://example.com/health-foods.jpg",
      },
      {
        id: 104,
        title: "Organic Foods",
        image_Url: "https://example.com/organic-foods.jpg",
      },
    ],
  },
  {
    id: 11,
    title: "Automotive",
    subTitle: "Car Accessories, Maintenance & More",
    image_Url:
      "https://purepng.com/public/uploads/thumbnail/sedan-2009-toyota-corolla-l0g.png",
    subcategories: [
      {
        id: 111,
        title: "Car Accessories",
        image_Url: "https://example.com/car-accessories.jpg",
      },
      {
        id: 112,
        title: "Tires & Wheels",
        image_Url: "https://example.com/tires.jpg",
      },
      {
        id: 113,
        title: "Car Maintenance",
        image_Url: "https://example.com/car-maintenance.jpg",
      },
      {
        id: 114,
        title: "Motorcycle",
        image_Url: "https://example.com/motorcycle.jpg",
      },
      {
        id: 115,
        title: "Car Care Products",
        image_Url: "https://example.com/car-care.jpg",
      },
      {
        id: 116,
        title: "Cars",
        image_Url: "https://example.com/car-care.jpg",
      },
    ],
  },
  {
    id: 12,
    title: "Phones & Accessories",
    subTitle: "Smartphones, Cases, Headphones & More",
    image_Url: "https://purepng.com/public/uploads/thumbnail/purepng.com-apple-iphone-xappleapple-iphonephonesmartphonemobile-devicetouch-screeniphone-xiphone-10electronicsobjects-251530689596fryq6.png",
    subcategories: [
      {
        id: 121,
        title: "Smartphones",
        image_Url: "https://example.com/smartphones.jpg",
      },
      {
        id: 122,
        title: "Phone Cases",
        image_Url: "https://example.com/cases.jpg",
      },
      {
        id: 123,
        title: "Headphones",
        image_Url: "https://example.com/headphones.jpg",
      },
      {
        id: 124,
        title: "Chargers & Cables",
        image_Url: "https://example.com/chargers.jpg",
      },
      {
        id: 125,
        title: "Screen Protectors",
        image_Url: "https://example.com/screen-protectors.jpg",
      },
      {
        id: 126,
        title: "Mobile Accessories",
        image_Url: "https://example.com/mobile-accessories.jpg",
      },
    ],
  },
  {
    id: 13,
    title: "Cameras & Photography",
    subTitle: "Digital Cameras, Lenses & Photography Equipment",
    image_Url: "https://purepng.com/public/uploads/large/purepng.com-digital-cameracameraphotographydigital-cameralens-1701528450175bkm4f.png",
    subcategories: [
      {
        id: 131,
        title: "Digital Cameras",
        image_Url: "https://example.com/digital-cameras.jpg",
      },
      {
        id: 132,
        title: "DSLR Cameras",
        image_Url: "https://example.com/dslr.jpg",
      },
      {
        id: 133,
        title: "Mirrorless Cameras",
        image_Url: "https://example.com/mirrorless.jpg",
      },
      {
        id: 134,
        title: "Camera Lenses",
        image_Url: "https://example.com/lenses.jpg",
      },
      {
        id: 135,
        title: "Camera Accessories",
        image_Url: "https://example.com/camera-accessories.jpg",
      },
      {
        id: 136,
        title: "Video Cameras",
        image_Url: "https://example.com/video-cameras.jpg",
      },
      {
        id: 137,
        title: "Lighting Equipment",
        image_Url: "https://example.com/lighting.jpg",
      },
      {
        id: 138,
        title: "Camera Bags",
        image_Url: "https://example.com/camera-bags.jpg",
      },
      {
        id: 139,
        title: "Gimbals & Stabilizers",
        image_Url: "https://example.com/gimbals.jpg",
      }
    ],
  }
];

export const footerProductLinks = [
  {
    name: "About us",
    link: "/about",
  },
  {
    name: "Careers",
    link: "/carrers",
  },
  {
    name: "Store Locations",
  },
  {
    name: "Our Blog",
  },
  {
    name: "Reviews",
  },
];

export const footercompanyLinks = [
  {
    name: "Game & Video",
  },
  {
    name: "Phone &Tablets",
  },
  {
    name: "Computers & Laptop",
  },
  {
    name: "Sport Watches",
  },
  {
    name: "Events",
  },
];

export const footerSupportLinks = [
  {
    name: "FAQ",
  },
  {
    name: "Reviews",
  },
  {
    name: "Contact Us",
  },
  {
    name: "Shipping",
  },
  {
    name: "Live chat",
  },
];
