import React from 'react'
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader'
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar'
import BottomNav from '../../components/Layout/BottomNav';
import CreateBid from '../../components/Shop/CreateBid';
const ShopCreateBid = () => {
  const isSmallScreen = () => {
    return window.innerWidth <= 768; // Adjust this width as per your requirement
  };

  return (
    <div>
      <DashboardHeader />
      <div className="flex items-center justify-between w-full">
        <div className="w-[80px] 800px:w-[330px] mb10">
          <DashboardSideBar active={4} />
        </div>
        <div className="w-full justify-center flex mb-10">
          <CreateBid />
        </div>
      </div>
      {/* Conditionally render BottomNav only on smaller screens */}
      {isSmallScreen() && <BottomNav />}
    </div>
  )
}

export default ShopCreateBid